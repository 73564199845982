//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AuditLogTable from '@/modules/audit-log/components/audit-log-table.vue';
import AuditLogToolbar from '@/modules/audit-log/components/audit-log-toolbar.vue';
import AuditLogFilter from '@/modules/audit-log/components/audit-log-filter.vue';

export default {
  name: 'app-audit-log-page',

  components: {
    [AuditLogFilter.name]: AuditLogFilter,
    [AuditLogToolbar.name]: AuditLogToolbar,
    [AuditLogTable.name]: AuditLogTable,
  },
};
